<template>
  <CustomModal v-model="showContactGroupModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{contactGroup != null && contactGroup._id != null ? $t('modal.titleEditContactGroup') : $t('modal.titleCreateContactGroup')}}</template>
    <div class="form" v-if="contactGroup != null && groupsById != null">
      <div class="row" >
        <div class="label" >{{ $t('modal.labelName') }}:</div>
        <div class="value"><input ref="contactGroupName" v-model="contactGroup.name" type="text" data-cy="input-contactgroupname"/></div>
      </div>
      <div class="row" v-if="parentContactGroup != null">
        <div class="label">{{ $t('modal.labelInherits') }}</div>
        <div class="value"><input v-model="contactGroup.inheritsRights" type="checkbox"/></div>
      </div>
      <div class="row" v-if="!contactGroup.inheritsRights">
        <div class="label">{{ $t('modal.labelRights') }}:</div>
        <div class="value">
          <table>
            <thead><th>{{ $t('modal.labelUserGroup') }}</th><th>{{ $t('modal.labelRead') }}</th><th>{{ $t('modal.labelWrite') }}</th><th class="removeColumn"></th></thead>
            <tbody>
              <tr v-for="ar in contactGroup.accessRights" :key="ar.userGroup">
                <td>{{getGroupName(ar.userGroup)}}</td>
                <td><input type="checkbox" value="READ" data-cy='contactGroup-permission-read' v-model="ar.read" @click="handleRightChange(ar, 'READ')"></td>
                <td><input type="checkbox" value="WRITE" data-cy='contactGroup-permission-write' v-model="ar.write" @click="handleRightChange(ar, 'WRITE')"></td>
                <td class="removeColumn"><span class="removeLink" @click="removeGroup(ar)"><i class='fas fa-trash'/></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="!contactGroup.inheritsRights">
        <div class="label"></div>
        <div class="value">
          {{ $t('modal.labelAddUserGroup') }}<br/>
          <select @change="addGroup" v-model="selectedGroup" data-cy="contactGroup-add-usergroup">
            <option value="-1" >{{ $t('general.pleaseChoose') }}</option>
            <option :value="g._id" v-for="g in proposedGroups" :key="g._id">{{g.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';

export default {
  name: 'CreateOrEditContactGroupModal',
  props: ['contactGroupToEdit', 'parentContactGroup'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showContactGroupModal: false,
      contactGroup: null,
      groups: [],
      groupsById: null,
      showGroupOptions: true,
      selectedGroup: '-1',
    };
  },
  watch: {
    contactGroupToEdit(newVal) {
      this.contactGroup = newVal;
      this.contactGroup.accessRights = this.contactGroup.accessRights.map((ar) => ({ ...ar, read: ar.type === 'READ', write: ar.type === 'WRITE' }));
    },
    /*
    parentContactGroup(newVal) {
      console.log(newVal);
    },
    */
  },
  mounted() {
    this.contactGroup = this.contactGroupToEdit;
    this.fetchGroups();
  },
  computed: {
    proposedGroups() {
      const existingIds = {};
      this.contactGroup.accessRights.forEach((g) => { existingIds[g.userGroup] = true; });
      const pgs = this.groups.filter((g) => !existingIds[g._id]);
      return pgs;
    },
  },
  methods: {
    handleRightChange(accessRight, type) {
      const ar = accessRight;
      ar.type = type;
      ar.read = type === 'READ';
      ar.write = type === 'WRITE';
    },
    getGroupName(g) {
      if (this.groupsById != null && this.groupsById[g] != null) {
        return this.groupsById[g].name;
      }
      return 'unknown';
    },
    async show() {
      await this.fetchGroups();
      this.showContactGroupModal = true;
    },
    hide() {
      this.showContactGroupModal = false;
    },
    async confirm() {
      if (this.contactGroup.name == null || this.contactGroup.name.trim() === '') {
        return;
      }
      // encrypt and send user
      try {
        this.contactGroup.name = CryptoHelper.encrypt(this.contactGroup.name);
        this.contactGroup.accessRights = this.contactGroup.accessRights.filter((ar) => ar.read || ar.write);
        this.contactGroup.accessRights = this.contactGroup.accessRights.map((ar) => ({ userGroup: ar.userGroup, type: ar.type }));
        if (this.contactGroup._id != null) {
          // update
          await ServiceHelper.sendDataToBackend(`contactGroup/${this.contactGroup._id}`, this.contactGroup, 'PUT');
        } else {
          // create
          const url = this.parentContactGroup != null ? `contactGroup/${this.parentContactGroup._id}` : 'contactGroup';
          await ServiceHelper.sendDataToBackend(url, this.contactGroup);
        }
        this.$emit('refresh');
        this.hide();
      } catch (error) {
        console.error(error);
        if (error.errorCode != null) {
          alert(this.$t(`error.code_${error.errorCode}`));
        }
      }
    },
    cancel() {
      this.hide();
    },
    async fetchGroups() {
      const data = await ServiceHelper.loadDataFromBackend('userGroups');
      const groupsById = {};
      data.payload.forEach((entry) => {
        if (entry.name != null) {
          const e = entry;
          e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          groupsById[e._id] = e;
        }
      });
      this.groups = data.payload;
      this.groupsById = groupsById;
      this.autoFocus();
      return null;
    },
    autoFocus() {
      if (!this.$refs.contactGroupName) {
        setTimeout(this.autoFocus, 200);
      } else {
        this.$refs.contactGroupName.focus();
      }
    },
    addGroup() {
      if (this.selectedGroup !== '-1') {
        this.contactGroup.accessRights.push({
          userGroup: this.selectedGroup,
          type: 'READ',
          read: true,
          write: false,
        });
        this.selectedGroup = '-1';
      }
    },
    removeGroup(group) {
      this.contactGroup.accessRights = this.contactGroup.accessRights.filter((ar) => ar.userGroup !== group.userGroup);
    },
  },
};
</script>

<style scoped>
::v-deep(.value table) {
  width: 100%;
}

::v-deep(.label) {
  vertical-align: top;
}

::v-deep(.value th) {
  background: lightgray;
}

::v-deep(.value td) {
  border-bottom: 1px solid lightgray;
}

::v-deep(.value select) {
  width: 100%;
}

::v-deep(.removeLink) {
  font-size: 12px;
  cursor: pointer;
  color: gray;
}

</style>
