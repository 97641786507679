<template>
  <CustomModal v-model="showContactModal" @confirm="confirm" @cancel="cancel" :showCancel="showOrHideCancel()">
    <template v-slot:title>
      <span v-if="!readOnly">{{contact != null && contact._id != null ? $t('modal.titleEditContact') : $t('modal.titleCreateContact')}}</span>
      <span v-else>{{$t('modal.titleContact')}}</span>
    </template>
    <div class="form contact" v-if="contact != null" :style="{height: `${dialogHeight}px`}">
      <div class="row" >
        <div class="label"><div class="icon"><i class='fas fa-graduation-cap'/></div> {{ $t('modal.labelTitle') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.title" type="text" data-cy="contact-title"/></div>
      </div>
      <div class="row" >
        <div class="label"><div class="icon"><i class='fas fa-user-circle'/></div> {{ $t('modal.labelFirstname') }}:<sup>*</sup></div>
        <div class="value"><input :disabled="readOnly" v-model="contact.firstname" type="text" data-cy="contact-firstname"/>
          <br/><div v-if="errorFirstname != null" class="fieldError">{{errorFirstname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='far fa-user-circle'/></div> {{ $t('modal.labelLastname') }}:<sup>*</sup></div>
        <div class="value"><input :disabled="readOnly" v-model="contact.lastname" type="text" data-cy="contact-lastname"/>
          <br/><div v-if="errorLastname != null" class="fieldError">{{errorLastname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='far fa-envelope'/></div> {{ $t('modal.labelEmail') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.email" type="text" data-cy="contact-email"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-industry'/></div> {{ $t('modal.labelCompany') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.company" type="text" data-cy="contact-company"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='far fa-building'/></div> {{ $t('modal.labelDepartment') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.department" type="text" data-cy="contact-department"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-sitemap'/></div> {{ $t('modal.labelPosition') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.position" type="text" data-cy="contact-position"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-phone'/></div> {{ $t('modal.labelPhone') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.phone" type="text" data-cy="contact-phone"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-mobile-alt'/></div> {{ $t('modal.labelMobile') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.mobile" type="text" data-cy="contact-mobile"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-fax'/></div> {{ $t('modal.labelFax') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.fax" type="text" data-cy="contact-fax"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-road'/></div> {{ $t('modal.labelStreet') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.street" type="text" data-cy="contact-street"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-globe'/></div> {{ $t('modal.labelZipCode') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.zipCode" type="text" data-cy="contact-zip"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-city'/></div> {{ $t('modal.labelCity') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.city" type="text" data-cy="contact-city"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-globe-europe'/></div> {{ $t('modal.labelCountry') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.country" type="text" data-cy="contact-country"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-house-user'/></div> {{ $t('modal.labelRoom') }}:</div>
        <div class="value"><input :disabled="readOnly" v-model="contact.room" type="text" data-cy="contact-room"/></div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';

export default {
  name: 'CreateOrEditContactModal',
  props: ['contactToEdit', 'parentContactGroup'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showContactModal: false,
      contact: null,
      dialogHeight: 200,
      readOnly: false,
      errorFirstname: null,
      errorLastname: null,
    };
  },
  watch: {
    contactToEdit(newVal) {
      this.contact = newVal;
    },
  },
  mounted() {
    this.contact = this.contactToEdit;
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    showOrHideCancel() {
      return this.readOnly ? false : this.$t('general.cancel');
    },
    resizeHandler() {
      this.dialogHeight = window.innerHeight - 300;
    },
    async show(readOnly) {
      this.errorFirstname = null;
      this.errorLastname = null;
      this.readOnly = readOnly;
      this.showOrHideCancel();
      this.showContactModal = true;
    },
    hide() {
      this.showContactModal = false;
    },
    async confirm() {
      if (this.readOnly) {
        this.hide();
        return;
      }
      // encrypt and send contact
      try {
        // we need at least firstname and lastname
        this.errorFirstname = null;
        this.errorLastname = null;

        if (this.contact.firstname == null || this.contact.firstname.trim() === '') {
          this.errorFirstname = this.$t('validationError.firstNameEmpty');
        }
        if (this.contact.lastname == null || this.contact.lastname.trim() === '') {
          this.errorLastname = this.$t('validationError.lastNameEmpty');
        }

        if (this.errorFirstname != null || this.errorLastname != null) {
          return;
        }

        this.contact.title = this.contact.title != null && this.contact.title.trim() !== '' ? CryptoHelper.encrypt(this.contact.title) : '';
        this.contact.firstname = this.contact.firstname != null && this.contact.firstname.trim() !== '' ? CryptoHelper.encrypt(this.contact.firstname) : '';
        this.contact.lastname = this.contact.lastname != null && this.contact.lastname.trim() !== '' ? CryptoHelper.encrypt(this.contact.lastname) : '';
        this.contact.content = CryptoHelper.encrypt(JSON.stringify({
          email: this.contact.email,
          phone: this.contact.phone,
          fax: this.contact.fax,
          mobile: this.contact.mobile,
          street: this.contact.street,
          zipCode: this.contact.zipCode,
          city: this.contact.city,
          country: this.contact.country,
          room: this.contact.room,
          department: this.contact.department,
          position: this.contact.position,
          company: this.contact.company,
        }));

        if (this.contact._id != null) {
          // update
          await ServiceHelper.sendDataToBackend(`contact/${this.contact._id}`, this.contact, 'PUT');
        } else {
          // create
          await ServiceHelper.sendDataToBackend(`contact/${this.parentContactGroup._id}`, this.contact);
        }
        this.$emit('refresh');
        this.hide();
      } catch (error) {
        console.error(error);
        if (error.errorCode != null) {
          alert(this.$t(`error.code_${error.errorCode}`));
        }
      }
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style scoped>
::v-deep(.modal_content .form.contact) {
  max-height: 580px;
  overflow-y: auto;
}
::v-deep(.modal_content .form .row .label .icon ){
  width: 30px;
  display: inline-block;
}

::v-deep(.modal_content .form .row .label svg) {
  color: var(--color_orange);
}

::v-deep(.modal_content .form .row .label) {
  vertical-align: middle;
  font-size: 16px;
}

::v-deep(.modal_content .form .row .fieldError) {
  color: var(--color_red);
  font-size: 12px;
}

::v-deep(.modal_content .form .row .value input:disabled) {
  color: black;
  opacity: 1;
  border: none;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  padding: 0;
}

@media (max-width: 800px) {
  ::v-deep(.modal_content .form .row .label) {
    display: block;
    width: calc(100% - 40px);
    font-size: 14px;
  }
  ::v-deep(.modal_content .form .row .value) {
    display: block;
    width: calc(100% - 40px);
    padding-left: 34px;
  }
}

</style>
