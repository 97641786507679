<template>
  <div class="explorer">
    <ContactBookComponent/>
  </div>
</template>

<script>
import ContactBookComponent from '@/components/ContactBookComponent.vue';

export default {
  name: 'ContactBookView',
  components: {
    ContactBookComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
