<template>
  <div class="exlporer" >
    <div class="page-title">
      <div @click="goUp" v-if='currentContactGroup != null' class='navButton narrow-screen'><i class="fas fa-arrow-up"/> ..</div>
      {{ $t('pageTitle.contactBook') }}
      <span class="navButton bgBrown refresh" @click="loadContactGroupContent"><i class="fas fa-sync"></i></span>
    </div>
    <div class="currentLocation" v-if='currentContactGroup != null && currentContactGroup.name != null'>
      <div @click="goUp" v-if='currentContactGroup != null' class='navButton wide-screen'><i class="fas fa-arrow-up"/> ..</div>
      <div class="currentLocationTitle"><i class="far fa-folder-open"/> {{currentContactGroup.name}}</div>
    </div>
    <div class="currentLocation" v-if='currentContactGroup == null'>{{ $t('general.root') }}</div>
    <br/>
    <EmptyListAnimationComponent v-if="contactGroups != null && contacts != null && contactGroups.length == 0 && contacts.length == 0"/>
    <div class="listWrapper contactBookWrapper" ref="contactBookWrapper" :style='{ height: contactBookHeight }'>
      <div class="listItemWrapper contactGroupTable" v-for="entry in contactGroups" :key="entry._id">
        <div class="listItem contactBookContactGroup" @click="openContactGroup(entry)"><div class="icon"><i class="far fa-address-book"/></div> {{entry.name}}</div>
        <div class="listActionWrapper" v-if="accessRight=='WRITE'">
          <span class="listAction contactBookAction" data-cy="contactbook-edit-group"
           @click="editContactGroup(entry)" :title="$t('action.edit')"><i class="fas fa-pen"/></span>
          <span class="listAction contactBookAction" data-cy="contactbook-delete-group"
           @click="deleteContactGroup(entry._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
        </div>
      </div>
      <div class="listItemWrapper contactsTable" v-for="entry in contacts" :key="entry._id">
        <div class="listItem contactBookContact" @click="openContact(entry)"><div class="icon"><i class="far fa-address-card"/></div> {{entry.title}} {{entry.firstname}} {{entry.lastname}}</div>
        <div class="listActionWrapper" v-if="accessRight=='WRITE'">
          <span class="listAction contactBookAction" data-cy="contactbook-edit-contact"
            @click="editContact(entry)" :title="$t('action.edit')"><i class="fas fa-pen"/></span>
          <span class="listAction contactBookAction" data-cy="contactbook-delete-contact"
            @click="deleteContact(entry._id)" :title="$t('action.delete')"><i class="fas fa-trash"/></span>
        </div>
      </div>
    </div>
    <div v-if="accessRight=='WRITE'" class="floating-add-button" @click="addContactGroup" data-cy="add-contact-group">
      <i class="fas fa-plus"/>&nbsp;<i class="fas fa-address-book"/>
    </div>
    <div v-if="currentContactGroup != null && accessRight=='WRITE'" class="floating-add-button left" @click="addContact" data-cy="add-contact">
      <i class="fas fa-plus"/>&nbsp;<i class="fas fa-address-card"/>
    </div>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
    <CreateOrEditContactGroupModal :contactGroupToEdit="contactGroupToEdit" :parentContactGroup="currentContactGroup" ref="createOrEditContactGroupModal" @refresh="loadContactGroupContent"/>
    <CreateOrEditContactModal :contactToEdit="contactToEdit" :parentContactGroup="currentContactGroup" ref="createOrEditContactModal" @refresh="loadContactGroupContent"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import CreateOrEditContactGroupModal from '@/components/modals/CreateOrEditContactGroupModal.vue';
import CreateOrEditContactModal from '@/components/modals/CreateOrEditContactModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import EmptyListAnimationComponent from './EmptyListAnimationComponent.vue';

export default {
  name: 'ContactBookComponent',
  props: [],
  components: {
    CreateOrEditContactGroupModal,
    CreateOrEditContactModal,
    ConfirmModal,
    EmptyListAnimationComponent,
  },
  data() {
    return {
      currentContactGroup: null,
      contactGroups: [],
      contacts: [],
      accessRight: '',
      dragdropListeners: [],
      contactGroupToEdit: null,
      contactBookHeight: '400px',
      contactToEdit: null,
    };
  },
  watch: {
  },
  mounted() {
    this.loadContactGroupContent();
    window.addEventListener('resize', this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.contactBookHeight = `${(window.innerHeight - this.$refs.contactBookWrapper.offsetTop - 10)}px`;
    },
    async loadContactGroupContent() {
      this.contacts = [];
      this.contactGroups = [];
      try {
        const url = this.currentContactGroup != null ? `contactGroup/${this.currentContactGroup._id}` : 'contactGroup';
        const data = await ServiceHelper.loadDataFromBackend(url);
        data.payload.contactGroups.forEach((entry) => {
          if (entry.name != null) {
            const e = entry;
            e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          }
        });
        data.payload.contacts.forEach((entry) => {
          if (entry.lastname != null) {
            const e = entry;
            e.lastname = CryptoHelper.decrypt(e.lastname).toString('utf-8');
          }
          if (entry.firstname != null) {
            const e = entry;
            e.firstname = CryptoHelper.decrypt(e.firstname).toString('utf-8');
          }
          if (entry.title != null) {
            const e = entry;
            e.title = CryptoHelper.decrypt(e.title).toString('utf-8');
          }
        });
        this.contactGroups = data.payload.contactGroups;
        this.contacts = data.payload.contacts;
        this.accessRight = data.payload.accessRightType;
        this.resizeHandler();
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addContactGroup() {
      this.contactGroupToEdit = { inheritsRights: this.currentContactGroup != null, accessRights: [] };
      this.$refs.createOrEditContactGroupModal.show();
      return null;
    },
    async addContact() {
      this.contactToEdit = { accessRights: [] };
      this.$refs.createOrEditContactModal.show();
      return null;
    },
    async deleteContactGroup(contactGroupId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `contactGroup/${contactGroupId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadContactGroupContent();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    async editContactGroup(contactGroup) {
      this.contactGroupToEdit = JSON.parse(JSON.stringify(contactGroup));
      this.$refs.createOrEditContactGroupModal.show();
      return null;
    },
    async editContact(contact) {
      this.openContact(contact, false);
    },
    async openContact(contact, readOnly = true) {
      try {
        // this.$refs.createOrEditContactModal.show();
        const url = `contact/${contact._id}`;
        const data = await ServiceHelper.loadDataFromBackend(url);
        const entry = data.payload;
        this.contactToEdit = { ...contact, ...JSON.parse(CryptoHelper.decrypt(entry.content)) };
        this.$refs.createOrEditContactModal.show(readOnly);
        this.resizeHandler();
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    openContactGroup(contactGroup) {
      this.parentContactGroup = this.currentContactGroup;
      const f = contactGroup;
      f.parentContactGroup = this.currentContactGroup;
      this.currentContactGroup = contactGroup;
      this.loadContactGroupContent();
    },
    async deleteContact(contactId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `contact/${contactId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadContactGroupContent();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    goUp() {
      if (this.currentContactGroup.parentContactGroup != null) {
        this.currentContactGroup = this.currentContactGroup.parentContactGroup;
      } else {
        this.currentContactGroup = null;
      }
      this.loadContactGroupContent();
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

.listWrapper .listItemWrapper:hover {
  background-color: var(--color_orange_half);
}

.listWrapper .listItemWrapper:last-of-type {
  margin-bottom: 100px;
}

.listWrapper .listItemWrapper .contactBookContactGroup {
  font-weight: 600;
}

.contactBookWrapper {
  overflow-y: auto;
}

</style>
